import io, { Socket } from 'socket.io-client';
import { JwtPayload, jwtDecode } from 'jwt-decode';
import {
  SocketClient,
  SocketRequest,
  SocketResponse,
} from 'data/protocols/socket';
import { makeReduxActiveMessage } from 'main/factories/usecases/message/Update';
import { closeModal } from 'utils/closeModal';
import { makeRemoteRespondConferenceWaitingRoom } from 'main/factories/usecases/conferenceWaitingRoom/RespondConferenceWaitingRoomFactory';
import { makeRemoteJoinConference } from 'main/factories/usecases/conference/JoinConferenceFactory';
import { useHistory } from 'react-router-dom';
import { History } from 'main/routes';
import { iStore } from 'domain/interfaces/models';
import storeDev from 'data/store';
import { makeReduxSetupConferenceWaitingRoom } from 'main/factories/usecases/conferenceWaitingRoom/SetupConferenceWaitingRoomFactory';

class SocketIO implements SocketClient {
  private static Instance: SocketIO;

  private static Socket: typeof Socket;

  public static getInstance(): SocketIO {
    if (!SocketIO.Instance) {
      SocketIO.Instance = new SocketIO();
    }

    return SocketIO.Instance;
  }

  public getSocket = (): typeof Socket => {
    return SocketIO.Socket;
  };

  /**
   * connect to the server.
   */
  public connect = (token: string): void => {
    console.log('>>> Connect token: ', token);
    const decoded: JwtPayload & { type: string } = jwtDecode(token);
    console.log('>>> Connect decoded: ', decoded.type);
    SocketIO.Socket = io(window.config.connection.socketIoUrl, {
      query: {
        ...(decoded.type === 'GUEST' ? { guestToken: token } : { token }),
      },
    });

    this.listening();
  };

  /**
   * Disconnect to the server.
   */
  public disconnect = (): void => {
    SocketIO.Socket.disconnect();
  };

  /**
   * Emit event.
   */
  public emit = (data: SocketRequest): Promise<SocketResponse> => {
    console.log('>>> Emit event socket: ', data);
    return new Promise((resolve, reject) => {
      SocketIO.Socket.emit(
        data.event,
        data.body,
        (err: any, dataResponse: any) => {
          console.log('error: ', err);
          console.log('data: ', dataResponse);
          if (dataResponse) resolve({ body: dataResponse });
          if (err) reject(err);
        },
      );
    });
  };

  private listening = (): void => {
    console.log('>>> Listening socket: ', SocketIO.Socket);
    SocketIO.Socket.on(
      'EV_PART_WAITING',
      (data: {
        user: {
          id: number;
          session: string;
          state: string;
          name: string;
          avatar: string;
        };
        conference: {
          id: number;
          short: string;
          title: string;
          descr: string;
        };
      }) => {
        console.log('>>> EV_PART_WAITING: ', data);
        try {
          const store: iStore = storeDev.getState();
          const { requestQueue } = store.conferenceWaitingRoom;
          makeReduxSetupConferenceWaitingRoom().setup({
            requestQueue: [...requestQueue, data],
          });
        } catch {
          console.log('>>> CATCH_EV_PART_WAITING: ', data);
        }
      },
    );

    SocketIO.Socket.on('EV_PART_REQUEST_RESPONSE', (data: any) => {
      const store: iStore = storeDev.getState();

      const { mic, cam, name } = store.conferenceWaitingRoom;
      console.log('>>> EV_PART_REQUEST_RESPONSE: ', data);

      if (data.response.status === 'AUTHORIZED') {
        History.getHistory().push(
          `/join?t=${data.conference.short}&u=${name}&c=${cam}&m=${mic}&sessionName=${data.response.sessionName}`,
        );
      } else {
        makeReduxActiveMessage().active({
          active: 'conferenceAccessDenied',
          actionCancel: () => {
            closeModal();
          },
        });
      }

      makeReduxSetupConferenceWaitingRoom().setup({ requesting: false });
    });

    SocketIO.Socket.on('SAC_REQUESTER_LEFT', (data: any) => {
      console.log('>>> SAC_REQUESTER_LEFT: ', data);
      History.getHistory().push('/sac/list');
    });

    SocketIO.Socket.on('SAC_REQUESTER_WAITING', (data: any) => {
      console.log('>>> SAC_REQUESTER_WAITING: ', data);
    });

    SocketIO.Socket.on('SAC_REQUESTER_JOIN', (data: any) => {
      console.log('>>> SAC_REQUESTER_JOIN: ', data);
    });

    SocketIO.Socket.on('SAC_REQUESTER_DROP', (data: any) => {
      console.log('>>> SAC_REQUESTER_DROP: ', data);
      // History.getHistory().push('/sac/list');
    });
  };
}

export default SocketIO.getInstance();
