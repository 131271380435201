/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-array-index-key */
import {
  Button,
  Chips,
  IconButton,
  Input,
  RadioGroup,
  RadioGroupItem,
  Typography,
} from '@wisecare-tech/design-system-web';
import { Header } from 'presentation/components/header';
import {
  daysMap,
  daysOfWeek,
  useSacConfig,
} from 'presentation/hooks/sac/createConfig';
import { DaysAbbreviation } from 'presentation/hooks/sac/types';
import React, { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CreateSacConfigSchema } from 'validation/sac/createConfig';
import { Container, SubHeader } from '../Sac/styles';
import {
  ChipsContainer,
  Content,
  CustomScheduleContainer,
  Footer,
  PhoneNumbersContainer,
  Row,
  ScheduleContainer,
  Section,
  SectionColumn,
  SectionRow,
  SectionsContainer,
} from './styles';

export const SacSettings: React.FC = () => {
  const {
    scheduleType,
    scheduleDays,
    loading,
    removePhone,
    handleScheduleType,
    handleSelectDay,
    setStartFinish,
    createNewHour,
    removeHour,
    createNewPhone,
    submitValues,
  } = useSacConfig();

  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { isValid, isDirty },
  } = useFormContext<CreateSacConfigSchema>();

  const phones = watch('phones') ?? [];
  const anotherPhones = phones.slice(1);

  const hasAnyDaySelected: boolean = useMemo(
    () => !!scheduleDays.length,
    [scheduleDays]
  );

  useEffect(() => {
    console.log('scheduleDays', scheduleDays);
  }, [scheduleDays])

  if (loading) return <div>loading...</div>;

  return (
    <Container>
      <Header title="teste" />
      <SubHeader>
        <IconButton
          id="button-back"
          variant="primary"
          icon="arrow_back"
          size="md"
        />

        <Typography id="subheader_title" variant="b2_14semibold">
          AtendeFácil
        </Typography>
      </SubHeader>
      <form
        onSubmit={handleSubmit(submitValues, data => console.log(data))}
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <SectionsContainer>
          <Content>
            <Section>
              <Typography variant="t3_16semibold">Dados gerais</Typography>
              <Input
                label="E-mail"
                placeholder="Digite aqui o e-mail do suporte"
                {...register('supportEmail')}
              />
              <PhoneNumbersContainer>
                <SectionRow>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                      gap: '0.5rem',
                    }}
                  >
                    <Input
                      id="phone-0"
                      key="phone-0"
                      label="Telefone"
                      placeholder="(xx) xxxxx-xxxx"
                      {...register(`phones.0`, {
                        pattern: {
                          value: /^\(\d{2}\)\s\d{5}-\d{4}$/,
                          message: 'Telefone inválido',
                        },
                      })}
                    />
                    {phones.length > 1 && (
                      <IconButton
                        variant="primary"
                        icon="delete"
                        type="button"
                        onClick={() => removePhone(0)}
                      />
                    )}
                  </div>
                  {anotherPhones.map((phone, index) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        gap: '0.5rem',
                      }}
                    >
                      <Input
                        id={`phone-${index + 1}`}
                        key={`phone-${index + 1}`}
                        label="Telefone"
                        placeholder="(xx) xxxxx-xxxx"
                        {...register(`phones.${index + 1}`, {
                          pattern: {
                            value: /^\(\d{2}\)\s\d{5}-\d{4}$/,
                            message: 'Telefone inválido',
                          },
                        })}
                      />
                      <IconButton
                        variant="primary"
                        icon="delete"
                        type="button"
                        onClick={() => removePhone(index + 1)}
                      />
                    </div>
                  ))}
                </SectionRow>
                <Button
                  type="button"
                  variant="text"
                  size="md"
                  icon="add"
                  onClick={createNewPhone}
                >
                  Adicionar telefone
                </Button>
              </PhoneNumbersContainer>
            </Section>
            <Section>
              <Typography variant="t3_16semibold">Horário</Typography>
              <SectionColumn>
                <Typography variant="t4_16medium">
                  Selecione os dias disponíveis para atendimento
                </Typography>
                <ChipsContainer>
                  {daysOfWeek.map(day => (
                    <Controller
                      control={control as any}
                      name="schedule"
                      render={({ field }) => (
                        <Chips
                          type="button"
                          text={daysMap[day.label].abbreviate}
                          onClick={() => handleSelectDay(day)}
                          variant={
                            scheduleDays.filter(
                              scheduleDay => scheduleDay.day === day.label,
                            ).length > 0
                              ? 'blue'
                              : 'white'
                          }
                          {...field}
                        />
                      )}
                    />
                  ))}
                </ChipsContainer>
              </SectionColumn>
              <RadioGroup
                {...register('scheduleType')}
                defaultValue={scheduleType}
                onValueChange={value =>
                  handleScheduleType(value as 'FIXED' | 'FLEXIBLE')
                }
              >
                <RadioGroupItem
                  value="FIXED"
                  disabled={scheduleDays.length === 0}
                >
                  <Typography variant="b1_16regular">
                    Definir horário padrão para os dias da semana
                  </Typography>
                </RadioGroupItem>
                {scheduleType === "FIXED" && (
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '0.5rem',
                      alignItems: 'flex-start',
                      padding: '0.5rem 0',
                    }}
                  >
                    <ScheduleContainer>
                      <CustomScheduleContainer>
                        <Typography variant="b3_14medium">Horário</Typography>
                        <div
                          style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '0.5rem',
                            alignItems: 'flex-start',
                            padding: '0.5rem 0',
                          }}
                        >
                          <>
                            <Row>
                              <Input
                                about="Horário de atendimento padrão para os dias da semana"
                                disabled={!hasAnyDaySelected}
                                type="time"
                                value={scheduleDays?.[0]?.hours[0].start}
                                onChange={e =>
                                  setStartFinish(
                                    e.target.value,
                                    scheduleDays?.[0]
                                      ?.day as DaysAbbreviation,
                                    'start',
                                    0,
                                  )
                                }
                              />
                              <Typography variant="b4_14regular">
                                até
                              </Typography>
                              <Input
                                about="Horário de atendimento padrão para os dias da semana"
                                disabled={!hasAnyDaySelected}
                                type="time"
                                onChange={e =>
                                  setStartFinish(
                                    e.target.value,
                                    scheduleDays?.[0]
                                      ?.day as DaysAbbreviation,
                                    'finish',
                                    0,
                                  )
                                }
                              />
                              {scheduleDays?.[0]?.hours.length > 1 && (
                                <IconButton
                                  variant="primary"
                                  icon="delete"
                                  type="button"
                                  onClick={() => removeHour('MO', 0)}
                                />
                              )}
                            </Row>
                          </>
                          {scheduleDays?.[0]?.hours.length < 3 && (
                            <Button
                              type="button"
                              variant="text"
                              icon="add"
                              onClick={() => createNewHour('MO')}
                            >
                              Adicionar mais horários
                            </Button>
                          )}
                          {scheduleDays?.[0]?.hours?.slice(1)?.map((_, indexHour) => (
                            <>
                              <Row>
                                <Input
                                  about="Horário de atendimento padrão para os dias da semana"
                                  type="time"
                                  value={_.start}
                                  onChange={e =>
                                    setStartFinish(
                                      e.target.value,
                                      scheduleDays?.[0]
                                        ?.day as DaysAbbreviation,
                                      'start',
                                      indexHour,
                                    )
                                  }
                                />
                                <Typography variant="b4_14regular">
                                  até
                                </Typography>
                                <Input
                                  about="Horário de atendimento padrão para os dias da semana"
                                  type="time"
                                  value={_.finish}
                                  onChange={e =>
                                    setStartFinish(
                                      e.target.value,
                                      scheduleDays?.[0]
                                        ?.day as DaysAbbreviation,
                                      'finish',
                                      indexHour,
                                    )
                                  }
                                />
                                {scheduleDays?.[0]?.hours.length > 1 && (
                                  <IconButton
                                    variant="primary"
                                    icon="delete"
                                    type="button"
                                    onClick={() => removeHour('MO', indexHour)}
                                  />
                                )}
                              </Row>
                            </>
                          ))}
                        </div>
                      </CustomScheduleContainer>
                    </ScheduleContainer>
                  </div>
                )}
                <RadioGroupItem
                  value="FLEXIBLE"
                  disabled={scheduleDays.length === 0}
                >
                  <Typography variant="b1_16regular">
                    Definir horários personalizados
                  </Typography>
                </RadioGroupItem>
              </RadioGroup>

              {scheduleType === 'FLEXIBLE' &&
                scheduleDays
                  .sort(
                    (a, b) =>
                      daysMap[a.day as DaysAbbreviation].value -
                      daysMap[b.day as DaysAbbreviation].value,
                  )
                  .map(day => (
                    <ScheduleContainer>
                      <Typography variant="t4_16medium">
                        {daysMap[day.day as DaysAbbreviation].fullLabel}
                      </Typography>
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: '0.5rem',
                          alignItems: 'flex-start',
                          padding: '0.5rem 0',
                        }}
                      >
                        {day?.hours?.map((_, index) => (
                          <CustomScheduleContainer>
                            <Row>
                              <Input
                                about="Horário de atendimento padrão para os dias da semana"
                                type="time"
                                value={_.start}
                                onChange={e =>
                                  setStartFinish(
                                    e.target.value,
                                    day.day as DaysAbbreviation,
                                    'start',
                                    index,
                                  )
                                }
                                style={{
                                  maxWidth: '96px',
                                }}
                              />
                              <Typography variant="b4_14regular">
                                até
                              </Typography>
                              <Input
                                about="Horário de atendimento padrão para os dias da semana"
                                type="time"
                                value={_.finish}
                                onChange={e =>
                                  setStartFinish(
                                    e.target.value,
                                    day.day as DaysAbbreviation,
                                    'finish',
                                    index,
                                  )
                                }
                                style={{
                                  maxWidth: '96px',
                                }}
                              />
                              {day.hours.length > 1 && (
                                <IconButton
                                  variant="primary"
                                  icon="delete"
                                  type="button"
                                  onClick={() =>
                                    removeHour(
                                      day.day as DaysAbbreviation,
                                      index,
                                    )
                                  }
                                />
                              )}
                            </Row>
                          </CustomScheduleContainer>
                        ))}
                        {day.hours.length < 3 && (
                          <Button
                            type="button"
                            variant="text"
                            icon="add"
                            onClick={() =>
                              createNewHour(day.day as DaysAbbreviation)
                            }
                          >
                            Adicionar mais horários
                          </Button>
                        )}
                      </div>
                    </ScheduleContainer>
                  ))}
            </Section>
          </Content>
        </SectionsContainer>
        <Footer>
          <Button
            type="submit"
            variant="primary"
            size="lg"
            disabled={!isValid || !isDirty}
          >
            Salvar alterações
          </Button>
        </Footer>
      </form>
    </Container>
  );
};
