import React, { useEffect } from 'react';
import SacWaitingRoom from 'presentation/pages/SacWaitingRoom';
import io from 'socket.io-client';
import { useLocation } from 'react-router';
import { makeReduxActiveMessage } from '../../usecases/message/Update';

interface iState {
  sacToken: string;
  request: number;
}

const MakeSacWaitingRoomFactory: React.FC = () => {
  const { sacToken, request } = useLocation<iState>().state;
  const socket = io(window.config.connection.socketIoUrl, {
    query: {
      sacToken,
    },
  });

  useEffect(() => {
    console.log('... Socket: ', socket);
    socket.on('connect', () => {
      console.log('... Connected to server');
    });

    socket.on('SAC_SPECIALIST_JOIN', (data: any) => {
      console.log(data);
      console.log(' >>> SAC_SPECIALIST_JOIN');
      makeReduxActiveMessage().active({
        active: 'sacProfessionalReady',
        data: { ...data?.specialist, request },
      });
    });

    socket.on('SAC_SPECIALIST_LEFT', (data: any) => {
      console.log(data);
      console.log(' >>> SAC_SPECIALIST_LEFT');
    });

    socket.on('SAC_SPECIALIST_DROP', (data: any) => {
      console.log(data);
      console.log(' >>> SAC_SPECIALIST_DROP');
    });

    return () => {
      socket.disconnect();
    };
  }, [socket]);
  return <SacWaitingRoom />;
};

export default MakeSacWaitingRoomFactory;
